import { gql } from "@apollo/client";
export const SECURITIES_FIELDS = gql`
  fragment SecurityFields on Security {
    id
    name
    isinCode
    issuerTmp: classType2 {
      id: code
      name: value
    }
    classType5 {
      code
      value
    }
    issuer {
      name
      id
    }
    allocations {
      group {
        code
      }
      sector {
        code
        namesAsMap
        name
      }
    }
    tagsAsSet
  }
`;

export const SECURITIES_LIST_FIELDS = gql`
  fragment SecurityListFields on Security {
    id
    name
    securityCode
    currency {
      name
    }
  }
`;
