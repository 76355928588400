import { ReactNode } from "react";
import {
  Slide,
  toast,
  ToastContainer,
  ToastContainerProps,
} from "react-toastify";

interface ToastProps extends Partial<ToastContainerProps> {
  children?: ReactNode;
}

export const Toast = ({
  position = "bottom-center",
  hideProgressBar = true,
  theme = "colored",
  transition = Slide,
  autoClose = false,
  draggablePercent = 60,
  ...props
}: ToastProps) => (
  <ToastContainer
    position={position}
    hideProgressBar={hideProgressBar}
    theme={theme}
    transition={transition}
    autoClose={autoClose}
    draggablePercent={draggablePercent}
    {...props}
  />
);
