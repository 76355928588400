import { MutableRefObject, useState, useEffect, useMemo } from "react";
import { ExecutionMethod } from "api/enums";
import { SecurityTypeCode } from "api/holdings/types";
import { useGetSecurityDetails } from "api/holdings/useGetSecurityDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { useGetIsSecurityPartnerTradeble } from "api/trading/useGetIsSecurityPartnerTradeble";
import { useSendTradeOrderSignature } from "api/trading/useSendTradeOrderSignature";
import { useTrade } from "api/trading/useTrade";
import {
  PortfolioSelect,
  DownloadableDocument,
  Button,
  Input,
  LabeledDiv,
  LoadingIndicator,
} from "components/index";
import { LabeledDivFlex } from "components/LabeledDiv/LabeledDivFlex";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { toast } from "react-toastify";
import { getBackendTranslation } from "utils/backTranslations";
import { handleNumberInputEvent, handleNumberPasteEvent } from "utils/input";
import { round, roundDown } from "utils/number";
import {
  getBlockSizeErrorTooltip,
  getBlockSizeMinTradeAmount,
  getTradeAmountTooltip,
} from "utils/trading";
import { addProtocolToUrl } from "utils/url";
import TradeOrderSignaturesForm, {
  Signatories,
} from "../TradeOrderSignaturesForm/useTradeOrderSignaturesForm";
import { useTradablePortfolioSelect } from "../useTradablePortfolioSelect";
import { useGetBuyTradeType } from "./useGetBuyTradeType";

export interface BuyModalInitialData {
  id: number;
}

interface BuyModalProps extends BuyModalInitialData {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

export const isSecurityTypeFund = (
  securityType: SecurityTypeCode | undefined
) => securityType === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE;

const getTradeType = (securityType: SecurityTypeCode | undefined) =>
  isSecurityTypeFund(securityType) ? "subscription" : "buy";

const FALLBACK_DECIMAL_COUNT = 2;

export const BuyModalContent = ({
  modalInitialFocusRef,
  onClose,
  id: securityId,
}: BuyModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { t, i18n } = useModifiedTranslation();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  const { isPartnerTradebable, loading: isPartnerTradebableLoading } =
    useGetIsSecurityPartnerTradeble(securityId.toString());

  const { setPortfolioId, portfolioOptions, portfolioId } =
    useTradablePortfolioSelect();

  const selectedPortfolioId = portfolioId;
  const selectedPortfolio = useMemo(() => {
    return portfolios.find((p) => p.id === portfolioId);
  }, [portfolios, portfolioId]);

  const { data: security, loading: loadingSecurity } = useGetSecurityDetails(
    securityId.toString(),
    selectedPortfolio?.currency?.securityCode
  );

  const { loading: loadingCash, data: portfolioData } = useGetBuyData(
    selectedPortfolioId,
    security?.currency?.securityCode
  );

  const defaultAccount =
    portfolioData?.accounts?.find(
      (a) => a?.currency?.securityCode === portfolioData?.currency?.securityCode
    ) || portfolioData?.accounts?.[0];

  const securityToAccountFxRate = 1 / (defaultAccount?.currency?.fxRate || 1);

  const { isTradeInUnits, canToggleTradeType, setIsTradeInUnits } =
    useGetBuyTradeType(security?.tagsAsSet, security?.type?.code);

  const [input, setInput] = useState<string>("");
  const inputAsNr = input ? Number(input) : 0;

  const securityAmountDecimalCount =
    security?.amountDecimalCount !== undefined
      ? security.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const securityCurrencyAmountDecimalCount =
    security?.currency.amountDecimalCount !== undefined
      ? security?.currency.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const portfolioCurrencyAmountDecimalCount =
    portfolioData?.currency.amountDecimalCount !== undefined
      ? portfolioData?.currency.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const accountCurrencyAmountDecimalCount =
    defaultAccount?.currency.amountDecimalCount !== undefined
      ? defaultAccount?.currency.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const inputBlockSize = isTradeInUnits
    ? securityAmountDecimalCount
    : portfolioCurrencyAmountDecimalCount;

  const securityName =
    security !== undefined
      ? getBackendTranslation(
          security?.name,
          security?.namesAsMap,
          i18n.language
        )
      : undefined;

  const securityToPortfolioFxRate = security?.fxRate || 1;
  const securityPrice = security?.latestMarketData?.price;

  const securityPriceInPfCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const securityPriceInAccCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * securityToAccountFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const unitsToBuyFromTradeAmount =
    securityPriceInPfCurrency !== undefined
      ? inputAsNr / (securityPriceInPfCurrency || 1)
      : undefined;

  const unitsToBuy = isTradeInUnits
    ? roundDown(inputAsNr, securityAmountDecimalCount)
    : unitsToBuyFromTradeAmount !== undefined
    ? roundDown(unitsToBuyFromTradeAmount, securityAmountDecimalCount)
    : undefined;

  const estimatedTradeAmountInPfCurrency =
    unitsToBuy !== undefined && securityPriceInPfCurrency !== undefined
      ? round(
          unitsToBuy * securityPriceInPfCurrency,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const estimatedTradeAmountInAccountCurrency =
    unitsToBuy !== undefined && securityPriceInAccCurrency !== undefined
      ? round(
          unitsToBuy * securityPriceInAccCurrency,
          accountCurrencyAmountDecimalCount
        )
      : undefined;

  const estimatedTradeAmountInSecurityCurrency =
    unitsToBuy !== undefined && securityPrice !== undefined
      ? round(unitsToBuy * securityPrice, securityCurrencyAmountDecimalCount)
      : undefined;

  const calculatedReportFxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInPfCurrency || 1);

  const calculatedAccountFxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInAccountCurrency || 1);

  const { handleTrade: handleBuy } = useTrade({
    tradeType: getTradeType(security?.type.code),
    portfolio: selectedPortfolio || portfolios[0],
    securityName: securityName || "-",
    units: isTradeInUnits ? unitsToBuy : undefined,
    tradeAmount: !isTradeInUnits
      ? estimatedTradeAmountInSecurityCurrency
      : undefined,
    securityCode: security?.securityCode || "",
    executionMethod: isTradeInUnits
      ? ExecutionMethod.UNITS
      : ExecutionMethod.NET_TRADE_AMOUNT,
    accountFxRate: calculatedAccountFxRate,
    reportFxRate: calculatedReportFxRate,
  });

  const availableCash =
    portfolioData?.portfolioReport.accountBalanceAdjustedWithOpenTradeOrders;
  const portfolioCurrency = selectedPortfolio?.currency.securityCode;
  const securityCurrency = security?.currency.securityCode;

  const insufficientCash =
    (availableCash || 0) < (estimatedTradeAmountInPfCurrency || 0); // less than trying to buy for

  // const { readonly } = useKeycloak();

  const tradeAmountTooltip =
    unitsToBuy !== undefined &&
    security !== undefined &&
    portfolioCurrency !== undefined
      ? getTradeAmountTooltip(
          unitsToBuy,
          security,
          security.fxRate,
          portfolioCurrency,
          i18n.language,
          t
        )
      : undefined;

  //min trade amount allowed to trade in this security
  //based on its block size only
  const blockSizeMinTradeAmountInPfCurrency =
    securityPrice !== undefined
      ? round(
          getBlockSizeMinTradeAmount(
            securityAmountDecimalCount,
            securityPrice
          ) * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const blockSizeTradeAmountError =
    !isTradeInUnits &&
    inputAsNr > 0 &&
    security !== undefined &&
    blockSizeMinTradeAmountInPfCurrency !== undefined &&
    portfolioCurrency !== undefined &&
    estimatedTradeAmountInPfCurrency !== undefined &&
    blockSizeMinTradeAmountInPfCurrency > estimatedTradeAmountInPfCurrency //input is lower than min allowed trade amount
      ? getBlockSizeErrorTooltip(
          blockSizeMinTradeAmountInPfCurrency,
          security,
          security.fxRate,
          portfolioCurrency,
          i18n.language,
          t,
          true
        )
      : undefined;

  useEffect(() => {
    //when switching between amount and trade amount
    //we must make sure the input is rounded to the allowed
    //amount of decimals
    setInput((currInput) =>
      currInput ? round(parseFloat(currInput), inputBlockSize).toString() : ""
    );
  }, [inputBlockSize]);

  const loading = loadingCash || loadingSecurity;

  const disableBuyButton = () => {
    return (
      loading ||
      inputAsNr === 0 ||
      insufficientCash ||
      !!blockSizeTradeAmountError ||
      // readonly ||
      !selectedPortfolio ||
      submitting
    );
  };

  const { sendTradeOrderSignature, isLoading: sendTradeOrderSignatureLoading } =
    useSendTradeOrderSignature();

  const [signatoriesFormIsValid, setIsSignatoriesFormValid] = useState(false);
  const [signatories, setSignatories] = useState<Signatories[]>([]);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await handleBuy();

      console.log("response", response);

      if (!response?.data) {
        throw new Error(t("tradingModal.createTradeError"));
      }

      if (isPartnerTradebable) {
        const tradeReference =
          response.data.importLimitedTradeOrder[1]["o.reference"];

        if (!tradeReference) {
          throw new Error(t("tradingModal.createTradeError"));
        }

        const tradeOrderSignature = {
          reference: tradeReference,
          invitations: signatories.map((signatory) => ({
            name: signatory.name,
            email: signatory.email,
          })),
        };
        await sendTradeOrderSignature(tradeOrderSignature);

        toast.success(t("tradingModal.createSignatureTradeSuccess"), {
          autoClose: 3000,
        });
      } else {
        toast.success(t("tradingModal.createTradeSuccess"), {
          autoClose: 3000,
        });
      }
      onClose();
    } catch (error: unknown) {
      toast.error(t("tradingModal.createTradeError"), {
        autoClose: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="grid min-w-[min(84vw,_375px)] gap-2">
      {securityName && (
        <LabeledDiv
          label={t("tradingModal.securityName")}
          className="text-2xl font-semibold"
        >
          {securityName}
        </LabeledDiv>
      )}
      {loadingSecurity && <LoadingIndicator size="sm" />}
      {security?.url2 && (
        <div className="w-fit">
          <DownloadableDocument
            url={addProtocolToUrl(security?.url2)}
            label={t("tradingModal.kiid")}
          />
        </div>
      )}
      <PortfolioSelect
        portfolioOptions={portfolioOptions}
        portfolioId={portfolioId}
        onChange={(newPortfolio) => setPortfolioId(newPortfolio.id)}
        label={t("tradingModal.portfolio")}
        error={!portfolioId ? t("tradingModal.selectPortfolioError") : ""}
      />
      {!loadingCash && (
        <LabeledDiv
          label={t("tradingModal.availableCash")}
          className="text-xl font-semibold text-gray-700"
        >
          {availableCash !== undefined && portfolioCurrency !== undefined
            ? t("numberWithCurrency", {
                value: availableCash,
                currency: portfolioCurrency,
              })
            : "-"}
        </LabeledDiv>
      )}
      {loadingCash && <LoadingIndicator size="xs" />}
      <Input
        disabled={!portfolioId}
        ref={modalInitialFocusRef}
        value={input}
        onChange={(event) => {
          handleNumberInputEvent(event, setInput, 0, undefined, inputBlockSize);
        }}
        onPaste={(event) => {
          handleNumberPasteEvent(event, setInput, 0, undefined, inputBlockSize);
        }}
        label={
          isTradeInUnits
            ? t("tradingModal.unitsInputLabel")
            : t("tradingModal.tradeAmountSimpleInputLabel")
        }
        type="text"
        error={
          portfolioId === undefined
            ? ""
            : !input || inputAsNr === 0
            ? " "
            : insufficientCash && !loading
            ? t("tradingModal.insufficientCashError")
            : ""
        }
      />
      {canToggleTradeType && (
        <>
          <div className="pointer-events-auto flex select-none divide-x divide-slate-400/20 overflow-hidden rounded-md bg-gray-50 text-[0.8125rem] font-medium leading-5 shadow-sm ring-1 ring-slate-700/10">
            <button
              className={`flex-1 cursor-pointer px-4 py-2 text-center ${
                isTradeInUnits ? "bg-gray-200" : ""
              }`}
              onClick={() => setIsTradeInUnits(true)}
            >
              {t("tradingModal.unitsButtonLabel")}
            </button>

            <button
              className={`flex-1 cursor-pointer px-4 py-2 text-center ${
                !isTradeInUnits ? "bg-gray-200" : ""
              }`}
              onClick={() => setIsTradeInUnits(false)}
            >
              {t("tradingModal.tradeAmountButtonLabel")}
            </button>
          </div>
        </>
      )}
      <div className="flex flex-col items-stretch gap-4 ">
        <div>
          <LabeledDivFlex
            alignText="center"
            tooltipContent={tradeAmountTooltip || blockSizeTradeAmountError}
            id="buyOrderModal-tradeAmount"
            label={t("tradingModal.approximateTradeAmount")}
            className="text-2xl font-semibold"
          >
            {portfolioCurrency !== undefined &&
            estimatedTradeAmountInPfCurrency !== undefined
              ? `${t("number", {
                  value: estimatedTradeAmountInPfCurrency,
                })} ${portfolioCurrency} `
              : "-"}
          </LabeledDivFlex>
          {securityCurrency &&
            portfolioCurrency &&
            portfolioCurrency !== securityCurrency && (
              <LabeledDivFlex
                alignText="center"
                id="buyOrderModal-tradeAmount"
                label={""}
                className="text-md"
              >
                (
                {estimatedTradeAmountInSecurityCurrency !== undefined &&
                securityCurrency !== undefined
                  ? `${t("number", {
                      value: estimatedTradeAmountInSecurityCurrency,
                    })} ${securityCurrency}`
                  : "-"}
                )
              </LabeledDivFlex>
            )}
        </div>
        {isPartnerTradebable && (
          <>
            <hr className="my-2" />
            <TradeOrderSignaturesForm
              setSignatories={setSignatories}
              setIsValid={setIsSignatoriesFormValid}
            />
          </>
        )}
        <hr className="my-2" />
        <Button
          disabled={
            disableBuyButton() ||
            (isPartnerTradebable && !signatoriesFormIsValid)
          }
          isLoading={
            submitting ||
            sendTradeOrderSignatureLoading ||
            isPartnerTradebableLoading
          }
          onClick={onSubmit}
        >
          {t("tradingModal.buyButtonLabel")}
        </Button>
      </div>
      <hr className="my-1" />
      <div className="max-w-[375px] text-center text-xs text-gray-600">
        {t("tradingModal.buyDisclaimer")}
      </div>
    </div>
  );
};
