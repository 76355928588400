import { ApexOptions } from "apexcharts";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import Chart from "react-apexcharts";
import theme from "tailwindTheme";
import { toLongISOString } from "utils/date";

export type TimeData = (number | Date)[];
export type SeriesData = {
  x: string;
  y: number;
};

export interface LineData {
  name: string;
  data: TimeData[];
}

export interface ExtraToolTipData {
  id: string;
  title: string;
  value: string;
}

const resetButton =
  '<div class="box-border -ml-24 -z-0 rounded-md inline-flex gap-1 items-center justify-center relative whitespace-nowrap align-middle cursor-pointer bg-secondary-100 hover:bg-hover text-white-100 !fill-white text-body-xsmall py-2 px-3"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.3 10.2165C9.75364 10.7629 8.61107 11.9054 7.82488 12.6916C7.36927 13.1472 6.63131 13.1475 6.1757 12.6919C5.4032 11.9194 4.28276 10.7989 3.70033 10.2165C1.87788 8.39405 1.87788 5.43928 3.70033 3.61684C5.52278 1.79439 8.47755 1.79439 10.3 3.61684C12.1224 5.43928 12.1224 8.39405 10.3 10.2165Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.75016 6.91667C8.75016 7.88317 7.96666 8.66667 7.00016 8.66667C6.03366 8.66667 5.25016 7.88317 5.25016 6.91667C5.25016 5.95017 6.03366 5.16667 7.00016 5.16667C7.96666 5.16667 8.75016 5.95017 8.75016 6.91667Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>Zooma tillbaka</div>';

interface LineChartProps {
  series: Array<LineData>;
  options?: ApexOptions;
  detailed?: boolean;
  isPerformanceChart?: boolean;
  extraToolTipData?: ExtraToolTipData[];
}

const lineChartDefaultOptions: ApexOptions = {
  chart: {
    width: "100%",
    fontFamily: "Inter, sans-serif",
    animations: {
      enabled: false,
    },
    sparkline: {
      enabled: true,
    },
    toolbar: {
      tools: {
        download: false,
        selection: false,
        zoom: "<i />",
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: resetButton,
        customIcons: [],
      },
    },
  },
  colors: [
    theme.colors.graph[1],
    theme.colors.graph[2],
    theme.colors.graph[3],
    theme.colors.graph[4],
    theme.colors.graph[5],
    theme.colors.graph[6],
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  plotOptions: {
    area: {
      fillTo: "end" as const,
    },
  },
  grid: {
    padding: {
      top: 30,
      right: 40,
      bottom: 10,
      left: 24,
    },
  },
  stroke: {
    width: 2,
    curve: "straight",
  },
  legend: {
    show: true,
    position: "bottom",
    showForSingleSeries: true,
    showForNullSeries: false,
  },
  dataLabels: { enabled: false },
  tooltip: {
    enabled: true,
    shared: true,
    intersect: false,
    x: {
      show: false,
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
  },
};

export const LineChart = ({
  series,
  options,
  detailed = false,
  isPerformanceChart = false,
  extraToolTipData,
}: LineChartProps) => {
  const { t } = useModifiedTranslation();
  const chartOptions = (
    data: Array<LineData>,
    extraToolTipData: ExtraToolTipData[] | undefined
  ): ApexOptions => {
    return {
      ...lineChartDefaultOptions,
      ...(detailed && {
        chart: {
          animations: {
            enabled: false,
          },
          events: {
            beforeZoom: function (ctx) {
              ctx.w.config.xaxis.range = undefined;
            },
          },
          ...lineChartDefaultOptions.chart,
          sparkline: { enabled: false },
        },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: true,
          intersect: false,
          x: {
            show: true,
            format: "dd MMM yyyy",
          },
          y: {
            formatter: (value: number, { series, seriesIndex }) => {
              return isPerformanceChart
                ? t("numberWithPercent", {
                    value,
                    formatParams: {
                      value: {
                        signDisplay: "always",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    },
                  })
                : t("number", {
                    value,
                    formatParams: {
                      value: {
                        maximumFractionDigits: getFractionDigits(
                          value,
                          series,
                          seriesIndex
                        ),
                        minimumFractionDigits: getFractionDigits(
                          value,
                          series,
                          seriesIndex
                        ),
                      },
                    },
                  });
            },
          },
        },
        markers: {
          size: 0,
        },
        yaxis: {
          floating: true,
          opposite: true,
          forceNiceScale: false,
          // Adds buffer space above the highest data point to prevent values from being cut off at the top of the chart.
          max: (max: number) => {
            if (max === 0) return 1;
            const buffer = Math.abs(max) < 1 ? 0.1 : max * 0.1;
            return max + buffer;
          },
          tickAmount: 7,
          labels: {
            offsetY: -4,
            offsetX: 36,
            align: "right",
            minWidth: 36,
            maxWidth: 0,
            formatter: (value: number, { seriesIndex, w }) => {
              const fraction = 2;
              // if (w?.globals?.series) {
              //   fraction = getFractionDigits(
              //     value,
              //     w?.globals?.series,
              //     seriesIndex
              //   );
              // }
              return isPerformanceChart
                ? t("numberWithPercent", {
                    value,
                    formatParams: {
                      value: {
                        signDisplay: "always",
                      },
                    },
                  })
                : t("number", {
                    value,
                    formatParams: {
                      value: {
                        maximumFractionDigits: fraction,
                        minimumFractionDigits: fraction,
                      },
                    },
                  });
            },
            style: {
              colors: theme.colors.primary[100],
              fontSize: "10px",
              fontFamily: "Inter, sans-serif",
            },
          },
        },
        xaxis: {
          type: "datetime",
          tooltip: {
            enabled: false, // Disable x-axis specific tooltip
          },
          labels: {
            offsetX: 2,
            show: true,
            rotate: 0,
            rotateAlways: true,
            maxHeight: 180,
            style: {
              colors: theme.colors.primary[100],
              fontSize: "12px",
              fontFamily: "Inter, sans-serif",
            },
          },
        },
      }),
      ...options,
    };
  };
  return (
    <div className="h-full">
      <Chart
        options={chartOptions(series, extraToolTipData)}
        series={series}
        type="area"
        height="100%"
      />
      {!detailed && (
        <XLabels
          labels={series[0].data.map((datum) =>
            toLongISOString(new Date(datum[0]))
          )}
        />
      )}
    </div>
  );
};

const getLabelPosition = (labels: string[], position: number) =>
  Math.floor((position * (labels.length - 1)) / 3);

const getFractionDigits = (
  value: number,
  series: number[][],
  seriesIndex: number
): number => {
  const values = series[seriesIndex];
  const isInRange = values.every(function (val) {
    return Math.abs(val - value) <= 3;
  });
  if (isInRange) return 2;
  if (value > 100) {
    return 0;
  }
  return 2;
};
interface XLabelsProps {
  labels: Array<string>;
}

const XLabels = ({ labels }: XLabelsProps) => {
  if (labels.length <= 2) {
    return <div className="min-h-[21px]" />;
  }

  return (
    <div className="relative mx-1 grid min-h-[21px] grid-cols-2 text-body-small font-medium text-primary-60">
      <div className="text-left">{labels[getLabelPosition(labels, 0)]}</div>
      {labels.length > 3 && (
        <div
          className="absolute "
          style={{
            left: `${
              (100 * getLabelPosition(labels, 1)) / getLabelPosition(labels, 3)
            }%`,
          }}
        >
          <div className="ml-[-50%]">{labels[getLabelPosition(labels, 1)]}</div>
        </div>
      )}
      {labels.length > 2 && (
        <div
          className="absolute "
          style={{
            left: `${
              (100 * getLabelPosition(labels, 2)) / getLabelPosition(labels, 3)
            }%`,
          }}
        >
          <div className="ml-[-50%]">{labels[getLabelPosition(labels, 2)]}</div>
        </div>
      )}
      <div className="text-right">{labels[getLabelPosition(labels, 3)]}</div>
    </div>
  );
};
