import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { useGetSubPortfolioIds } from "api/generic/useGetSubPortfolioIds";
import { getFetchPolicyOptions } from "api/utils";
import { useDateRangeState } from "providers/DateRangeProvider";
import { toLongISOString } from "utils/date";
import { TRANSACTION_FIELDS } from "./fragments";
import { PortfolioTransactionsQuery, Transaction } from "./types";

export const TRANSACTIONS_QUERY = gql`
  ${TRANSACTION_FIELDS}
  query GetPortfolioTransactions(
    $startDate: String
    $endDate: String
    $portfolioIds: [String]
  ) {
    portfolios(ids: $portfolioIds) {
      id
      transactions(startDate: $startDate, endDate: $endDate, status: "OK") {
        ...TransactionsFields
      }
    }
  }
`;

export const useGetPortfolioTransactions = (
  portfolioId: number | undefined,
  options?: QueryHookOptions
) => {
  const portfolioIds = useGetSubPortfolioIds(portfolioId);
  const {startDate, endDate, fetchPolicyString} = useDateRangeState();


  const { loading, error, data } = useQuery<PortfolioTransactionsQuery>(
    TRANSACTIONS_QUERY,
    {
      variables: {
        startDate: toLongISOString(startDate),
        endDate: toLongISOString(endDate),
        portfolioIds: portfolioIds,
      },
      ...getFetchPolicyOptions(
        `useGetPortfolioTransactions.${
          portfolioIds.join("-")
        }.${fetchPolicyString}`
      ),
      ...options,
    }
  );

  return {
    loading,
    error,
    data: data?.portfolios.reduce((prev, curr) => {
      if (curr.transactions?.length) prev.push(...curr.transactions);
      return prev;
    }, [] as Transaction[]),
  };
};
