import { Fragment, MutableRefObject, ReactNode, MouseEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import classNames from "classnames";
import { useContentPosition } from "./hooks/useModalPosition";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  modalInitialFocusRef: MutableRefObject<null>;
  header?: string;
  properties?: {
    x: number;
    y: number;
  };
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  modalInitialFocusRef,
  header,
  properties,
}: ModalProps) => {
  const { x, y } = properties || {};
  const isCustomLocation = x && y;
  const { modalRef, modalPosition } = useContentPosition({
    x: x,
    y: y,
  });

  const handleClose = (value: boolean) => {
    if (!value) return; // Only handle closing events

    // Check if the most recent click was within a select dropdown
    const target = document.activeElement;
    const isSelectDropdown = target?.closest('[role="listbox"]');

    if (!isSelectDropdown) {
      onClose();
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={handleClose} initialFocus={modalInitialFocusRef}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames("fixed inset-0 z-30 bg-black/25", {
              "bg-black/5": isCustomLocation,
            })}
            aria-hidden="true"
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 z-30 flex items-baseline justify-center overflow-auto p-4">
            <div
              ref={modalRef}
              style={modalPosition}
              className={classNames({
                "flex justify-center items-center min-h-full":
                  !isCustomLocation,
                absolute: isCustomLocation,
                // "absolute top-[50px]": x && y
              })}
            >
              <Dialog.Panel className="grid w-full overflow-auto rounded-lg border bg-white-100 shadow-lg">
                {!!header && (
                  <div className="flex items-center justify-between bg-primary-10 p-4 text-2xl font-bold md:px-6">
                    <div>{header}</div>
                    <button
                      type="button"
                      onClick={onClose}
                      className="hover:bg-secondary-200/10 cursor-pointer rounded-lg border-2 border-transparent outline-none focus:border-secondary-60"
                    >
                      <CloseIcon className="w-8" />
                    </button>
                  </div>
                )}
                <div className="p-4 md:px-6">{children}</div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
